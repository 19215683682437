// @flow
import React from 'react';
import { graphql } from 'gatsby';
import type { FrontMatter, ImageType } from '../../utils/types';
import {
  AllImagesLink,
  CanyonRatingCard,
  GlossaryLink as G,
  Image,
  Layout,
  withFrontMatter,
} from '../../components';

type Props = {
  frontMatter: FrontMatter,
  data: {
    _1: ImageType,
    _2: ImageType,
    _3: ImageType,
    _4: ImageType,
  },
};

class Blog_2020_07_12_Lincolns_First_Canyon extends React.PureComponent<Props> {
  render() {
    const { data, frontMatter } = this.props;
    return (
      <Layout frontMatter={frontMatter}>
        <CanyonRatingCard rating="3CI" title="Rocky Mouth Canyon" />
        <p>
          I don't even have pictures of us on rappel, but I went canyoneering
          with one of my boys this afternoon. It was his first real{' '}
          <G id="3-technical-rating">technical</G> canyon and I'm really proud
          of him.
        </p>
        <Image
          image={data._1}
          caption="Sunny selfie on our way up the wooden steps"
        />
        <p>
          We also encountered an unexpected difficulty that made the experience
          less than ideal for my son and I want to talk about that.
        </p>
        <Image
          image={data._2}
          caption="Lincoln posing at the waterfall before hiking up and around"
        />
        <p>
          The most important thing to remember when taking kids is that it must
          be done at a relaxed pace. We stopped at every scramble, every bug,
          cave, bridge, and trickle of water on the way to the top. What would
          have normally taken ten minutes took thirty.
        </p>
        <Image
          image={data._3}
          caption="One of the bigger obstacles to climb for a kid"
        />
        <p>
          In order to give Lincoln some responsibility I had him carry his
          harness, safety tether, helmet, and snacks in his backpack. He is
          pretty tough and didn't complain about having to carry it. As a matter
          of fact, he insisted on carrying it the whole time, even on the
          sketchy dirt slide to get down into the drainage.
        </p>
        <Image
          image={data._4}
          caption="All geared up before the technical section"
        />
        <p>
          Everything went fine on the approach but two things went "wrong" on
          the technical part of it that are worth bringing up.
        </p>
        <ol>
          <li>
            Starting the first rappel, Lincoln had like a 2mm scab on his knee -
            that he kept picking the week prior - but it got scraped on the rock
            and made him bleed for a second.
          </li>
          <li>
            The second problem was on the second rappel there is a place where
            you have to get into the waterfall a little. I did this and he got
            splashed and shivered the rest of the way down to the ground.
            <br />I was able to stay out of the water the rest of the descent
            but it required me to deviate far from the fall line. It was also
            slippery, we were rappelling tandem, and if I slipped we would have
            slid a good twenty feet right back into the waterfall.
          </li>
        </ol>
        <p>
          Afterwards the rappels ate snacks, warmed up in the sun, and then
          headed home. As I'm writing this trip report he is standing next to me
          asking to go again so I will conclude that it was a success and we'll
          probably go again today.
        </p>
        <AllImagesLink id="2020-07-12-lincolns-first-canyon" />
        <hr />
        <p>
          And we went again! This time he <em>loved</em> it because his brothers
          were there to watch him do it. His older brother then begged his mom
          to let him go as well. So older brother went immediately after. Then
          it started thundering so we headed home.
        </p>
        <p>
          I added two videos from that second trip to the link with all the
          images. One video for each of my children who went down the waterfall.
        </p>
      </Layout>
    );
  }
}

export default withFrontMatter('2020-07-12-lincolns-first-canyon')(
  Blog_2020_07_12_Lincolns_First_Canyon
);

export const query = graphql`
  query {
    _1: file(
      relativePath: {
        eq: "images/blog/2020-07-12-lincolns-first-canyon/_1.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _2: file(
      relativePath: {
        eq: "images/blog/2020-07-12-lincolns-first-canyon/_2.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _3: file(
      relativePath: {
        eq: "images/blog/2020-07-12-lincolns-first-canyon/_3.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _4: file(
      relativePath: {
        eq: "images/blog/2020-07-12-lincolns-first-canyon/_4.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
